/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState } from "react";
import api from "../utils/api";
import useAuth from "../utils/useAuth";
import { NotificationManager } from "react-notifications";
import { ConfirmModal } from "./confirm-modal";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import deleteIcon from '../images/trash-icon.svg';
import { TableContext } from "./table";
import { AssignToPatientModal } from "./assign-kit-modal";
import { ChangeBrandModal } from "./change-brand-modal";
import Notes from "../components/notes";
import { EditOrderKit } from "../components/edit-order-kit";
import SendResult from "../components/send-result";
import changeIcon from '../images/change-icon.svg'
import { Row } from "./defaults";


const PillWrapper = styled.div`
    padding: 10px 20px;
    color: white;
    background: ${props => props.background};
    border-radius: 100px;
`;

const PillButton = styled(PillWrapper)`
    background: rgba(245, 246, 250, 1);
    color: rgba(79, 89, 240, 1);
    cursor: pointer;
`;

const DeletePillButton = styled(PillButton)`
    background: transparent;
    border: 1px solid rgba(0,0,0,.1);
    ${props => props.disabled ? 'opacity: 0.5; pointer-events: none;' : ''};
`;

const DeleteIcon = styled.img`
    height: 15px;
    margin-top: 4px;
`;

const ChangeIcon = styled.img`
    height: 15px;
    cursor: pointer;
    margin-left: 5px;
    ${props => props.visible ? 'cursor: pointer' : 'cursor: initial'};
    ${props => props.visible ? 'opacity: 1' : 'opacity: 0'};
`


export const BrandSelector = (props) => {
    const [changeVisible, setChangeVisible] = useState(false);
    const onPointerOver = () => setChangeVisible(true);
    const onPointerOut = () => setChangeVisible(false);
    const { claims } = useAuth();

    return <div 
    onMouseOver={onPointerOver} 
    onMouseOut={onPointerOut}>
        {props?.brand}
        {claims?.brand === 1 && 
        <ChangeIcon 
        onClick={() => props.setVisible(props.code)} 
        src={changeIcon} visible={changeVisible}/>}
    </div>
}

export const KitCommandContext = React.createContext({});

export const KitCommandContextWrapper = ({ children }) => {

    const [kitNoteId, setKitNoteId] = useState(null);
    const [kitResultId, setKitResultId] = useState(null);
    const [kitUnscanId, setKitUnscanId] = useState(null);
    const [assignToPatientKit, setAssignToPatientKit] = useState(null);
    const [selectedBrandKit, setSelectedBrandKit] = useState(null);
    const [kitForDeletion, setKitForDeletion] = useState(null);
    const [orderKit, setOrderKit] = useState(null);
    const navigate = useNavigate();

    const {
        fetchKits,
        data,
        setLoading
    } = useContext(TableContext);
    const barcodeInputRef = useRef();

    const unscanKit = async () => {
        setLoading(true)
        await api.delete(`/admin/scan`, {
            data: { barcode: kitUnscanId}
        });
        setKitUnscanId(null)
        navigate(0)
        NotificationManager.success('Kit successfully unscanned')
    }

    const deleteKit = async () => {
        setLoading(true)
        await api.post(`/admin/delete-kit`, kitForDeletion);
        setKitForDeletion(null)
        navigate(0)
        NotificationManager.success('Kit successfully deleted')
    }

    return <KitCommandContext.Provider value={{
        kitNoteId, setKitNoteId,
        kitResultId, setKitResultId,
        assignToPatientKit, setAssignToPatientKit,
        selectedBrandKit, setSelectedBrandKit,
        kitForDeletion, setKitForDeletion,
        orderKit, setOrderKit,
        kitUnscanId, setKitUnscanId,
    }}>
        {kitResultId && <SendResult 
            onSuccess={() => fetchKits('IN_LAB')} 
            barcode={kitResultId} 
            setVisible={setKitResultId}/>}
        {selectedBrandKit && <ChangeBrandModal 
            data={data} 
            fetchKits={fetchKits}
            setVisible={setSelectedBrandKit} 
            barcode={selectedBrandKit} />}
        {!!assignToPatientKit && <AssignToPatientModal 
            fetchKits={fetchKits}
            barcode={assignToPatientKit} 
            setVisible={() => setAssignToPatientKit(null)}  />}
        {kitNoteId && <Notes 
            barcodeInputRef={barcodeInputRef} 
            id={kitNoteId} 
            setVisible={setKitNoteId} />}
        {kitForDeletion && <ConfirmModal 
            onConfirm={deleteKit} 
            setVisible={setKitForDeletion} 
            prompt='Do you wish to delete this kit?' 
        />}
        {kitUnscanId && <ConfirmModal 
            onConfirm={unscanKit} 
            setVisible={setKitUnscanId} 
            prompt='Do you want to Unscan the kit?' 
        />}
        {orderKit && <EditOrderKit
            setVisible={setOrderKit}
            id={orderKit}
            data={data}
            onSuccess={() => fetchKits('NEW_ORDER')} 
        />}
        {children}
    </KitCommandContext.Provider>
}


const InLabCommands = ({
    id, 
    status,
    ...props
}) => {
    const { claims } = useAuth();
    const navigate = useNavigate();

    const { 
        setKitNoteId, 
        setAssignToPatientKit,
        setKitResultId,
        setKitUnscanId
    } = useContext(KitCommandContext);


    return <>
        <PillButton style={{ marginRight: 16 }} onClick={() => {
            setKitNoteId(id)
        }} >Notes</PillButton>
        
        {status === 'IN_LAB' && claims?.brand === 1 && <PillButton onClick={() => setKitResultId(props.code)} style={{ 
            marginRight: 16,
        }}>Send Result</PillButton>}

        {status === 'IN_LAB' && claims?.brand === 1 && <PillButton onClick={() => setKitUnscanId(props.code)} style={{ 
            marginRight: 16,
        }}>Unscan</PillButton>}
        
        {props.user_id ? <PillButton onClick={() => navigate(`/dashboard/kit/${props.code}`)} style={{ 
            marginRight: 16,
            pointerEvents: props.user_id ? 'initial' : 'none',
            opacity: props.user_id ? 1 : 0.2
        }}>Patient File</PillButton> : <PillButton style={{ marginRight: 16 }} onClick={() => setAssignToPatientKit(props.code)} >Assign to Patient</PillButton>}  
    </>
}

const UnassignedCommands = ({ id, ...props }) => {

    const { 
        setAssignToPatientKit,
    } = useContext(KitCommandContext);

    return <>
        <PillButton style={{ marginRight: 16 }} onClick={() => setAssignToPatientKit(props.code)} >Assign to Patient</PillButton>
    </>
}

const OrderNowCommands = ({ id, ...props }) => {

    const { 
        setOrderKit
    } = useContext(KitCommandContext);

    return <>
        <PillButton style={{ marginRight: 16 }} onClick={() => setOrderKit(id)}>Complete kit information </PillButton>
    </>
}

export const KitCommands  = ({id, fetchKits, status, ...values}) => {
    const { loading } = useContext(TableContext);
    const { setKitForDeletion } = useContext(KitCommandContext);
    const { claims } = useAuth()

    const statuses = {
        'UNASSIGNED': <UnassignedCommands {...{ id, ...values }} />,
        'NEW_ORDER': <OrderNowCommands {...{ id: values.kit_id, ...values }} />
    }

    const onDelete = () => {
        setKitForDeletion({
            [status === 'NEW_ORDER' ? 'id' : 'barcode']: status === 'NEW_ORDER' ? values.kit_id : id
        })
    }

    return <Row style={{ alignItems: 'center'}}> 
    {
        statuses[status] || <InLabCommands status={status} id={id} {...values} />
    }
        {claims?.brand === 1 && claims.role === 'SUPER_ADMIN' && 
        <DeletePillButton disabled={loading} onClick={onDelete}>
            <DeleteIcon src={deleteIcon} alt='delete' />
        </DeletePillButton>}
    </Row>
}