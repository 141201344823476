/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Select from "react-dropdown-select";
import styled from 'styled-components';
import { Modal } from './modal';
import { Button, Column, Row } from './defaults';
import api from '../utils/api';
import { fetchBrands, fetchLaboratory, fetchTests } from "../utils/brand";
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const InputWrapper = styled(Column)`
    input {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        font-size: 16px;
        outline: none;
        font-family: 'Satoshi-Medium';

        &:disabled {
            opacity: 0.5
        }
    } 
`;

const DropdownInput = ({ options, value, onChange, ...inputProps }) => {
    
    return <DropdownInputWrapper>
        <Select options={options} values={value ? [value] : []} 
        onChange={(e) => {
            onChange(e[0])
        }}
        {...inputProps} />
    </DropdownInputWrapper>

}

const DropdownInputWrapper = styled(Column)`
    * {
        outline: none;
        font-family: 'Satoshi-Regular';
    }
    .react-dropdown-select {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 10px 10px;
        outline: none;
        height: 43px;
    } 
    .react-dropdown-select-input{
        font-size: 16px;
        padding: 0px;
        font-family: 'Satoshi-Regular';
    }
`;

const Input = React.forwardRef(({ label, ...inputProps }, ref) => {
    return  <InputWrapper>
        <input ref={ref} {...inputProps} />
    </InputWrapper>
})

const PrButton = styled(Button)`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Error = styled.div`
    color: red;
    font-size: 14px;
`;

function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function EditOrderKit({
    setVisible,
    id,
    data,
    onSuccess
}) {
    const [loading, setLoading] = useState(false);
    const [barcode, setBarcode] = useState('');
    const [error, setError] = useState(null);
    const [brands, setBrands] = useState([])
    const [laboratories, setLaboratories] = useState([])
    const [laboratory, setLaboratory] = useState()
    const [brand, setBrand] = useState()
    const [kit, setKit] = useState()
    const [testType, setTestType] = useState()
    const input = React.useRef();
    const navigate = useNavigate();

    const tests = [
        { label: 'Age', value: 2},
        { label: 'Folate', value: 1},
    ]

    useEffect(() => {
        const fetch = async () => {
            setBrands(await fetchBrands())
            setLaboratories(await fetchLaboratory())
        }
        fetch()
    }, [])

    useEffect(() => {
        if (kit) {
            const currentBrand = brands.find(brand_option => brand_option.label === kit.brand)
            const currentLaboratory = laboratories.find(laboraty_option => laboraty_option.label === kit.laboratory)
            const currentTestType = tests.find(test_option => test_option.label === kit.test)
            if (currentBrand) setBrand(currentBrand)
            if (currentLaboratory) setLaboratory(currentLaboratory)
            if (currentTestType) setTestType(currentTestType)
        }
    }, [kit, brands, laboratories, tests])

    useEffect(() => {
        if (data) {
            setKit(data.find(kit_data => kit_data.kit_id === id))
        }
    }, [data])

    const send = async () => {
        setLoading(true);
        setError(null)
        if (!barcode || !testType || !laboratory || !brand) {
            setError('Missing fields')
        }
        
        try {
            await api.post('/admin/complete-order-kit', {
                id: kit.kit_id,
                barcode: barcode,
                laboratory: laboratory.value,
                brand: brand.value,
                'test_type': testType.value
            })
            onSuccess()
            setTimeout(() => {
                NotificationManager.success(`Kit ${kit.kit_id} was sucessfully associated with barcode ${barcode}`);
            }, 100)
            setVisible()
        } catch (err) {
            console.log(err?.response?.data?.title)
            
            if (err?.response?.data?.title) setError(err?.response?.data?.title)
                else setError("We couldn't update this kit.")
        }
        setLoading(false)
    }

    const disabled = !barcode || !testType || !laboratory || !brand;
    
    const onKeyDown = (event) => {
        if (event.key === 'Enter' && barcode) send()
    }

    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            <TopSection>
                <Title>Edit Order Kit Information</Title>
            </TopSection>
            <FormSection>
                <Input onKeyDown={onKeyDown} ref={input} value={barcode} onChange={(e) => setBarcode(e.target.value?.trim())} placeholder='barcode' />
                <Column>
                    <div style={{ fontSize: 13 }}>Brand</div>
                    <DropdownInput label='Brand' options={brands} value={brand} onChange={(e) => setBrand(e)} />
                </Column>
                <Column>
                    <div style={{ fontSize: 13 }}>Laboratory</div>
                    <DropdownInput label='Laboratory' options={laboratories} value={laboratory} onChange={(e) => setLaboratory(e)} />
                </Column>
                <Column>
                    <div style={{ fontSize: 13 }}>Test Type</div>
                    <DropdownInput label='Test Type' options={tests} value={testType} onChange={(e) => setTestType(e)} />
                </Column>
                <Row style={{ gap: 15, justifyContent: 'flex-end', alignItems: 'center', marginTop: 20 }}>
                    <Error>{error}</Error>
                    <div style={{ flex: 1 }} />
                    <PrButton loading={loading} onClick={send} disabled={disabled}>
                        Submit
                    </ PrButton>
                </Row>
            </FormSection>
        </Box>
    </Modal>

}