/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row } from "../components/defaults";
import api from "../utils/api";
import { Search } from "../components/search";
import moment from "moment";
import useAuth from "../utils/useAuth";
import returnIcon from '../images/change-icon.svg';
import { NotificationManager } from "react-notifications";
import loadingIcon from '../images/3-dots-move-purple.svg';

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';


const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'Satoshi-Medium'
`;

const BrandRowWrapper = styled(Row)`
    height: 40px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -25px;
    font-size: 15px;
    color: rgba(0,0,0,.5);
    border-bottom: 1px solid rgba(0,0,0,.1);
    

    &:hover {
        * {
            color: rgba(0,0,0,1);
        }

        #delete-btn {
            display: initial;
        }
    }
    
`;

const BrandName = styled.div`
    flex: 1;
    gap: 3px;
    display: flex;
    align-items: center;
    ${props => props.header && 'color: black'};
    ${props => props.header ? 'font-family: Satoshi-Bold' : 'font-family: Satoshi-Medium;'}
`;





const LogRow = ({header, recreate, ...props}) => {

    const canSoftDelete = props.event === 'SOFT_DELETE';
    const [loading, setLoading] = useState(false);

    const _recreate = async () => {
        setLoading(true);
        await recreate(props.id);
        setLoading(false);
    }

    return <BrandRowWrapper {...props}>
        <BrandName header={header}>{props.barcode ? props.barcode : '---'}</BrandName>
        <BrandName header={header}>
            {canSoftDelete && <img 
            onClick={_recreate} 
            style={{ 
                pointerEvents: loading ? 'none' : 'initial',
                height: 15, 
                cursor: 'pointer' 
            }} alt='Restore' src={returnIcon} />}
            {props.event}
        </BrandName>
        <BrandName header={header}>{header ? props.date : moment(props.date).format('MM/DD/YYYY')}</BrandName>
        <BrandName header={header}>{header ? props.time : moment(props.date).format('hh:mm')}</BrandName>
    </BrandRowWrapper>
}

export const LogsPage = () => {

    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState('');
    const [logs, setLogs] = useState([]);
    const { currentUser, isLoading } = useAuth();

    const recreate = async (id) => {
        try {
            await api.post('/kit/restore', { id })
            NotificationManager.success('Kit restored successfully');
            await fetchLogs();
        } catch (err) {
            NotificationManager.error('This entry was already restored.')
        }
    }

    const fetchLogs = async () => {
        setLoading(true);
        const url = query ? `/logs?barcode=${query}` : '/logs';
        const result = await api.get(url);
        setLogs(result.data?.logs)
        setLoading(false);
    }

    useEffect(() => {
        if (currentUser && !isLoading) fetchLogs()
    }, [query, currentUser, isLoading])

    return <Contents>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
            <TopTitle>Logs</TopTitle>
            <Search placeholder='Search for a kit barcode' setQuery={setQuery} />
        </Row>
        {loading && <Row style={{ height: 300, alignItems: 'center', justifyContent: 'center'}}>
            <img src={loadingIcon} alt='loading' style={{ height: 50 }} />
        </Row>}
        {!loading && <LogRow header barcode='Barcode' event='Event' date='Date' time='Time' />}
        {!loading && logs?.map((log, idx) => <LogRow 
            key={idx} recreate={recreate}
            {...log} idx={idx} />)}
        {(!loading && !logs?.length) && <div style={{ marginTop: 20 }}>No items found</div>}
    </Contents>

}
