/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Column, Row } from "../components/defaults";
import useAuth from "../utils/useAuth";
import api from "../utils/api";
import { NotificationManager } from "react-notifications";
import deleteIcon from '../images/trash-icon.svg';
import loadingIcon from '../images/3-dots-move.svg';
import { alphanumericSort } from "../utils/sort";
import { LoadingMask } from "./loading-mask";
import arrowIcon from '../images/scan-arrow.svg';


const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';

const DeleteIcon = styled.img`
    height: 15px;
    width: 15px;
    pointer-events: ${ props => props.disabled ? 'none' : 'initial'};
    opacity: ${ props => props.disabled ? 0.2 : 1 };
    cursor: ${ props => props.disabled ? 'initial': 'pointer' };
`;

const ArrowIcon = styled.img`
    height: 15px;
    width: 15px;
    cursor: pointer;
    opacity: ${ props => props.disabled ? 0.2 : 1 };
    transform: ${props => props.down ? 'rotate(180deg)' : 'initial'};
    pointer-events: ${ props => props.disabled ? 'none' : 'initial'};
`;

const Grid = styled.div`
    grid-auto-rows: 45px;
    grid-template-columns: ${props => props.forceUpdate ? '120px 150px 250px 250px 250px 100px 350px 220px 100px 150px 150px 150px' : '120px 150px 250px 250px 350px 100px 220px 100px 150px 100px 100px'} ;
    display: grid;
    min-width: 1000px;
`;

const CellWrapper = styled(Row)`
    align-self: center;
    color: ${SELECTED_COLOR};
    font-size: 14px;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(231, 231, 233, 1);
    align-self: stretch;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    div {
        font-family: 'Satoshi-Bold';
        white-space: nowrap;
    }
`;

const HeaderCellWrapper = styled(CellWrapper)`
    color: rgba(5, 31, 115, 1);
    background: rgba(249, 249, 249, 1);
    align-items: center;
`;

const Cell = ({children, style = {}}) => {
    return <CellWrapper style={{...style, flex: 1 }}>
        <Row style={{ flex: 1 }}>{children}</Row>
    </CellWrapper>
}

const HeaderCell = ({children, style = {}}) => {
    return <HeaderCellWrapper style={style}>
        <div>{children}</div>
    </HeaderCellWrapper>
}


const TableRow = ({
    values,
    columns,
    idx
}) => {
    return columns.map(({ id, formatter }, i) => <Cell style={i === 0 ? { paddingLeft: 24 } : i === columns.length - 1 ? { paddingRight: 24 } : {}}>
        { formatter ? formatter(values, idx) : values[id] || '-'} 
    </Cell>)   
}


const TableHeader = ({
    columns,
}) => {

    return columns.map(({ title }, i) => <HeaderCell style={i === 0 ? { paddingLeft: 24 } : i === columns.length - 1 ? { paddingRight: 24 } : {}}>
        { title }</HeaderCell>)
}

const TableWrapper = styled(Column)`
    overflow: scroll;
`;

const TableInput = styled.input`
    outline: none;
    height: 35px;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,.5);
    padding: 12px 10px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: 'Satoshi-Regular';
    flex: 1;
    
    &:disabled {
        background: rgba(0,0,0,.01);
        border: 1px solid rgba(0,0,0,.1);
        pointer-events: none;
        color: black;
    }
`;

const rowStatus = {
    PROCESSED: 'PROCESSED',
    USER_NOT_REGISTERED: 'USER_NOT_REGISTERED',
    MISSING_INFORMATION: 'MISSING_INFORMATION',
}

const statusSpecs = {
    [rowStatus.PROCESSED]: {
        children: 'Kit was Scanned',
        background: 'rgba(79, 222, 119, 1)'
    }, 
    [rowStatus.USER_NOT_REGISTERED]: {
        children: 'Kit has no user associated',
        background: 'rgba(255, 77, 109, 1)'
    },
    [rowStatus.MISSING_INFORMATION]: {
        children: 'Kit is not on DB',
        background: 'rgba(255, 77, 109, 1)'
    }
}


const getCurrentColumn = (rows, currentRow) => {
    const status = rows.find((r, i) => i === currentRow)?.status;
    const currentColumn = !status ? 'barcode' : 'additional_info';
    return currentColumn
}

const PillWrapper = styled(Column)`
    padding: 10px 20px;
    color: white;
    background: ${props => props.background};
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

const LoadingIcon = styled.img`
    height: 20px;
    align-self: center;
    justify-self: center;
`;

const Checkbox = styled.div`
    background: ${props => props.selected ? 'rgba(79, 89, 240, 1)' : 'rgba(255,255,255,0.1)'};
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 2px;
    width: 15px;
    height: 15px;
    opacity: ${props => props.disabled && !props.selected ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
    cursor: ${props => props.selected ? 'initial' : 'pointer'};
`;

const UpdateButton = styled.div`
    background: rgba(79, 89, 240, 1);
    color: white;
    border-radius: 100px;
    padding: 8px 15px;
    font-size: 12px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => !props.disabled ? 'initial' : 'none'};
`;


export const ScanTable = ({ 
    scanActive, 
    rows, 
    setRows,
    loading,
    setLoading,
}) => {

    const [provisoryLabNumber, setProvisoryLabNumber] = useState(null)
    const [labNumberFocused, setLabNumberFocused] = useState(false);
    const [swapping, setSwapping] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [overrideCurrentRow, setOverrideCurrentRow] = useState(null)
    const { currentUser, isLoading, claims } = useAuth();

    const currentRow = ![null, undefined].includes(overrideCurrentRow) ? overrideCurrentRow : Math.max(rows.findIndex((r) => 
        !r.success 
    ), 0)

    const currentColumn =  getCurrentColumn(rows, currentRow);

    useEffect(() => {
        const handleBarcodeInput = async (event) => {
            if (event.key === "Enter") {
                const scannedData = event.target.value;
                setRows((existing) => existing.map((e, i) => {
                    if (i === currentRow) return {...e, barcode: scannedData}
                    return existing
                }))
                await scan({ barcode: scannedData })
            }
        }

        if (scanActive && currentColumn === 'barcode') {
            window.addEventListener('keypress', handleBarcodeInput);
        } else {
            window.removeEventListener('keypress', handleBarcodeInput);
        }
        return () => {
            window.removeEventListener('keypress', handleBarcodeInput);
        };
    }, [scanActive, currentColumn]);
    

    const update = async (values) => {

        setLoading(true)
        try {
            const response = await api.put('/admin/scan', {
                barcode: values.barcode,
                additional_info: values.additional_info,
                lab_number: values.lab_number,
                force_submit: [rowStatus.MISSING_INFORMATION, rowStatus.USER_NOT_REGISTERED].includes(values.status) && currentColumn === 'additional_info'
            })

            let idx = rows.findIndex((r) => r.lab_number === values.lab_number);

            setRows((existing) => existing.map((e, i) => {
                if (idx  === i) {
                    const output = {...e, success: true}
                    output.email = response.data?.user_data?.email;
                    output.name = response.data?.user_data?.name;
                    output.date_of_birth = response.data?.user_data?.dob ? new Date(response.data?.user_data?.dob).toLocaleDateString() : '';
                    output.scanned_at = response.data?.user_data?.scanned_at ? new Date(response.data?.user_data?.scanned_at).toLocaleDateString() : '';
                    output.last_biological_age = response.data?.user_data?.last_biological_age;
                    output.chronological_age = response.data?.user_data?.chronological_age;   
                    output.status = (response.data.status === 'PROCESSED' || response.data.success) ? 'PROCESSED' : response.data.status;;

                    return output;
                }
                else return e
            }))

            if (response.data?.success) setOverrideCurrentRow(null)

        } catch (err) {
            NotificationManager.error('Error!')
            //console.log(err);
        }
        setLoading(false);
    }

    const getLabNumber = async () => {
        const result = await api.get('/admin/lab-number');
        const newRows = []

        const pendingLabNumbers = result.data.pending_lab_numbers
        pendingLabNumbers.sort((a, b) => parseInt(a) - parseInt(b))
        pendingLabNumbers.forEach((lab_number) => {
            newRows.push({
                lab_number: lab_number,
                barcode: '',
                status: null,
                additional_info: '',
                barcodeRef: React.createRef(),
                additionalInfoRef: React.createRef(),
            })
        })
        
        for (let i = newRows.length + 1; i < 150; i++) {
            newRows.push({
                lab_number: result.data.highest_lab_number + i,
                barcode: '',
                status: null,
                additional_info: '',
                barcodeRef: React.createRef(),
                additionalInfoRef: React.createRef(),
            })
        }

        newRows.sort(alphanumericSort())
        setRows(newRows)
    }

    useEffect(() => {
        if (currentUser && claims) {
            getLabNumber();
        }
    }, [currentUser, claims, isLoading])

    const onChange = (idx, value, key) => {
        setRows((existing) => existing.map((e, i) => {
            if (idx === i) return {...e, [key]: value}
            else return e
        }))
    }

    useEffect(() => {
        const row = rows.find((r, i) => i === currentRow);
        if (!row) return;
        if (currentColumn === 'barcode') row.barcodeRef?.current?.focus();
        if (currentColumn === 'additional_info') row.additionalInfoRef?.current?.focus()
    }, [currentColumn, currentRow])
    


    const scan = async (values, idx) => {
        setLoading(true);
        try {
            const response = await api.post('/admin/scan', {
                lab_number: values.lab_number,
                barcode: values.barcode,
                additional_info: values.additional_info || '',
                force_submit: [rowStatus.MISSING_INFORMATION, rowStatus.USER_NOT_REGISTERED].includes(values.status) && currentColumn === 'additional_info'
            });

            if (response.data.success) {
                setRows((existing) => existing.map((e, i) => {
                    if (idx  === i) {
                        const output = {...e, success: true}
                        if (response.data.status === 'PROCESSED' || response.data.success) output.status = 'PROCESSED';
                        output.email = response.data?.user_data?.email;
                        output.name = response.data?.user_data?.name;
                        output.date_of_birth = response.data?.user_data?.dob ? new Date(response.data?.user_data?.dob).toLocaleDateString() : '';
                        output.scanned_at = response.data?.user_data?.scanned_at ? new Date(response.data?.user_data?.scanned_at).toLocaleDateString() : '';
                        output.last_biological_age = response.data?.user_data?.last_biological_age;
                        output.chronological_age = response.data?.user_data?.chronological_age;                    
                        return output;
                    }
                    else return e
                }))
                setLoading(false)
                setTimeout(() => rows.find((r, i) => i === currentRow)?.barcodeRef?.current?.focus(), 500)
                return;
            } 
        

            let newLabNumber = null


            setRows((existing) => existing.map((e, i) => {
                if (i === idx) {
                    if (response.data.lab_number !== values.lab_number) newLabNumber = response.data.lab_number
                    return {...e, 
                        status: response.data.status,
                        ...(response.data.lab_number ? { lab_number: response.data.lab_number } : {})
                    }
                } 
                else if (newLabNumber && e.lab_number > values.lab_number) return {
                    ...e,
                    lab_number: newLabNumber + (e.lab_number - values.lab_number) //update lab numbers
                } 
                else return e
            }))
        } catch (err) {
            const previous = idx > 0 && rows.find((r, i) => i === idx - 1);
            
            if (values.barcode === previous.barcode) {
                NotificationManager.error('Duplicate scan!');
                setRows((existing) => existing.map((e, i) => {
                    if (i === idx) return {...e, barcode: '', status: null, additional_info: ''}
                    return e
                }))
            } else {
                NotificationManager.error(err?.response?.data?.title);
            }
            setTimeout(() => {
                rows.find((row, i) => i === idx).barcodeRef.current.focus();
            }, 20);
        }

        setLoading(false)
    }

    const swap = async (direction, values, index) => {

        setSwapping(true);
        const current = rows[index];
        const indexToSwap = direction === 'up' ? index + 1 : index - 1;
        const toSwap = rows[indexToSwap];
        await api.put('/admin/scan/swap', {
            barcodes: [current.barcode, toSwap.barcode]
        });

        setRows((existing) => existing.map((row, i) => {
            if (i === index) return {
                ...toSwap,
                lab_number: current.lab_number,
            } 
            if (i === indexToSwap) return {
                ...current,
                lab_number: toSwap.lab_number,
            }
            return row
        }))

        setOverrideCurrentRow(indexToSwap)
        
        setSwapping(false);

    }

    const onKeyDown = async (e, values, idx) => {

        if (labNumberFocused && e.key === 'Enter') {
            if (isNaN(parseInt(provisoryLabNumber))) return
            setRows((current) => {
                return current.map((c, i) => 
                    i === idx ? { ...c, lab_number: provisoryLabNumber } :
                    i > idx ? { ...c, lab_number: parseInt(provisoryLabNumber) + (i - idx) } :
                    c
                )
            })
            return;
        }

        if (labNumberFocused) {
            return
        }

        if (![null, undefined].includes(overrideCurrentRow) && e.key === 'Enter') {
            const values = rows.find((r, i) => i === overrideCurrentRow);
            if (values?.status === 'PROCESSED') {
                return await update(values)
            } else {
                return await scan(values, overrideCurrentRow)
            }
        };

        if (e.key === 'Escape') {
            setRows((existing) => existing.map((e, i) => {
                if (i === currentRow) return {...e, barcode: '', status: null, additionalInfo: ''}
                else return e
            }))
            return;
        }
        
        if (e.key === 'Enter' && values.barcode !== '' && values.lab_number !== '') await scan(values, idx)
    }

    const onClickCheckbox = (values, idx) => {
        setOverrideCurrentRow(idx);
    }

    const onClickDelete = async (values, idx) => {
        setDeleting(true)
        try {
          
            await api.delete('/admin/scan', { 
                data: { barcode: values.barcode, update_next_lab_numbers: true }
            });
            setRows((rows) => {
                let newRows = rows.map((row, i) => {
                    if (i >= idx) {
                        const nextRow = rows[i + 1];
                        if (nextRow) return {...nextRow, lab_number: row.lab_number }
                        else return null
                    } 
                    else return row;
                })
                newRows = newRows.filter((r) => r !== null);
                return newRows;
            })
        } catch (err) {
            //console.log('err deleting');
        }
        setDeleting(false)
    }

    const columns = [
        { id: 'cell', title: '', formatter: (values, i) => {
            
            const prevValues = i > 0 && rows[i - 1]
            const nextValues = i < rows.length - 1 && rows[i + 1]
            const selected = (i === currentRow || i === overrideCurrentRow);

            if (values.status) console.log(values.status)
            const nextDisabled = loading || deleting || swapping || nextValues?.status !== 'PROCESSED' || values?.status !== 'PROCESSED';
            const prevDisabled = loading || deleting || swapping || prevValues?.status !== 'PROCESSED' || values?.status !== 'PROCESSED';

            
            return <Row style={{ gap: 10 }}>
                <DeleteIcon disabled={
                    !values.status || deleting || i !== currentRow
                } onClick={() => onClickDelete(values, i)} 
                src={deleteIcon} />
                <Checkbox disabled={
                    (loading) || (deleting) || (swapping)
                } onClick={() => onClickCheckbox(values, i)} selected={i === currentRow}/>
                {selected && <ArrowIcon onClick={() => swap('up', values, i)} src={arrowIcon} disabled={nextDisabled} />}   
                {selected && <ArrowIcon onClick={() => swap('down', values, i)} src={arrowIcon} disabled={prevDisabled} down />}   
                </Row>
        } },
        { id: 'lab_number', title: 'Lab. Number', formatter: (values, idx) => {
            return <TableInput 
                ref={values.labnumberRef}
                onKeyDown={(e) => onKeyDown(e, values, idx)}
                value={labNumberFocused && idx === currentRow ? provisoryLabNumber : values.lab_number}
                onFocus={() => {
                    setProvisoryLabNumber(values.lab_number)
                    setLabNumberFocused(true)
                }}
                onBlur={() => setLabNumberFocused(false)}
                style={{ width: 100 }}
                disabled={overrideCurrentRow === idx ? false : loading || !(idx === currentRow) || (deleting) || swapping}
                onChange={(e) => {
                    if (labNumberFocused) setProvisoryLabNumber(e.target.value)
                    else onChange(idx, e.target.value, 'lab_number')
                }} />
        } },
        { id: 'barcode', title: 'Barcode', formatter: (values, idx) => {            
            return <TableInput 
                ref={values.barcodeRef}
                onKeyDown={(e) => onKeyDown(e, values, idx)}
                value={values.barcode}
                disabled={overrideCurrentRow === idx ? false : loading || !(idx === currentRow && currentColumn === 'barcode') || (deleting) || swapping}
                onChange={(e) => onChange(idx, e.target.value, 'barcode')} />
        }},
        { id: 'status', title: 'Status', formatter: (values, idx) => {
            if (loading && idx === currentRow) return <PillWrapper background={'rgba(0,0,0,.4)'}>
                <LoadingIcon src={loadingIcon} />
            </PillWrapper>
            if (values.status === null) return '-'
            return <PillWrapper {...statusSpecs[values.status]} />
        } },
        { id: 'additional_info', title: 'Additional Information', formatter: (values, idx) => {
            return <TableInput 
                ref={values.additionalInfoRef}
                onKeyDown={(e) => onKeyDown(e, values, idx)}
                value={values.additional_info}
                disabled={overrideCurrentRow === idx ? false : loading || !(idx === currentRow && currentColumn === 'additional_info') || deleting || swapping}
                onChange={(e) => onChange(idx, e.target.value,'additional_info')} />
        } },
        { id: 'scanned_at', title: 'Scanned At' },
        { id: 'email', title: 'Email' },
        { id: 'date_of_birth', title: 'DOB' },
        { id: 'name', title: 'Name' },
        { id: 'chronological_age', title: 'Chron. Age' },
        { id: 'last_biological_age', title: 'Last Bio Age' }
    ];


    if (![null, undefined].includes(overrideCurrentRow)) columns.push({
        id: '', title: '', formatter: (values) => {
            return (values.lab_number === overrideCurrentRow) ? 
                <UpdateButton disabled={loading || deleting || swapping} onClick={() => update(values)}>Update</UpdateButton> : <div></div>

        }
    })


    return <>
        {loading && <LoadingMask />}
        <TableWrapper>
            <Column style={{ minHeight: '50vh'}}>
            <Grid forceUpdate={![null, undefined].includes(overrideCurrentRow)} loading={loading}>
                <TableHeader columns={columns} />
                {rows.map((d, i) => <TableRow idx={i} values={d} columns={columns} key={i} {...d}/>)}
            </Grid>
            </Column>
        </TableWrapper>
    </>
    
}







